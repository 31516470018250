<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-sheet
          class="pa-3 d-flex"
          :class="{
            'flex-column': $vuetify.breakpoint.mdAndDown,
            'flex-row flex-grow-1': $vuetify.breakpoint.lgAndUp,
          }"
          elevation="1"
          rounded
        >
          <div>
            <p class="headline ma-0">{{ $changeLang(page_data.title) }}</p>
            <p class="subtitle-1 ma-0">{{ $changeLang(page_data.subtitle) }}</p>
            <div class="my-1">
              <span v-for="tag in page_data.tags" :key="tag">
                <v-chip color="#294664" class="mr-1" :ripple="false" label>{{
                  tag
                }}</v-chip>
              </span>
            </div>
          </div>
          <div
            class="d-flex"
            :class="{
              'flex-column justify-start flex-shrink-0':
                $vuetify.breakpoint.mdAndDown,
              'flex-row justify-end flex-grow-1': $vuetify.breakpoint.lgAndUp,
            }"
          >
            <v-btn
              color="success"
              :disabled="buyBtnStatus"
              :loading="buyBtnStatus"
              @click.prevent="buyAction"
            >
              <span v-if="buyBtnTextStatus === 1">{{ $t("main.buyNow") }}</span>
              <span v-if="buyBtnTextStatus === 2">{{
                $t("main.setting")
              }}</span>
              <span v-if="buyBtnTextStatus === 3">{{ $t("main.renew") }}</span>
            </v-btn>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row dense v-show="allCartLoading">
      <v-col>
        <MainLoading />
      </v-col>
    </v-row>
    <v-row dense v-show="!allCartLoading">
      <v-col cols="12" lg="8" md="8" sm="12">
        <!-- <button @click="reloadChart">load data</button> -->
        <!-- <div class="text-h6">Growth Chart</div> -->
        <!-- d-flex flex-column justify-space-between -->
        <div style="height: 100%" class="d-flex flex-column justify-start">
          <v-card class="mb-2" v-on:click="openGrowthDDD" :ripple="false">
            <v-card-title>Growth Chart</v-card-title>
            <!-- <div class="text-h6">Statistics</div> -->
            <GrowthChart v-bind:data="render_growth_option" />
          </v-card>

          <!-- statistics for mobile view-->

          <!-- -->
          <div
            class="d-flex mb-2"
            :class="{
              'flex-column ': $vuetify.breakpoint.mdAndDown,
              'flex-row': $vuetify.breakpoint.lgAndUp,
            }"
          >
            <v-flex lg8>
              <v-card v-on:click="openHistogramOfReturnDialog" :ripple="false">
                <!-- <v-btn class="mt-2" v-on:click="reloadChart">Reload Chart</v-btn> -->
                <div class="text-h6 text-center">Histogram of Return</div>
                <DailyReturnChart
                  :chartData="render_daily_return_chart_data"
                  :options="render_daily_return_chart_option"
                  :height="150"
                />
                <div class="text-h5 text-center">
                  {{ page_payoff_ratio_value }}
                </div>
                <div class="caption text-center grey--text">Payoff Ratio</div>
              </v-card>
            </v-flex>
            <v-flex lg4>
              <v-card
                :class="{
                  'mt-2': $vuetify.breakpoint.mdAndDown,
                  'ml-2 maxHeight': $vuetify.breakpoint.lgAndUp,
                }"
                v-on:click="openMonthlyYearlyReturnDialog"
                :ripple="false"
              >
                <div class="text-h6 text-center mx-3">
                  Monthly / Yearly Return
                </div>
                <div
                  class="d-flex"
                  :class="{
                    'flex-row': $vuetify.breakpoint.mdAndDown,
                    'flex-column': $vuetify.breakpoint.lgAndUp,
                  }"
                >
                  <v-flex>
                    <div class="text-center pa-1">Best Monthly Return</div>
                    <div class="text-h5 text-center pa-1">
                      {{ page_best_monthly_return_value }}
                    </div>
                    <div class="green--text text--darken-1 text-center">
                      {{ page_best_monthly_return_date }}
                    </div>
                  </v-flex>
                  <v-flex>
                    <div class="text-center pa-1">Worst Monthly Return</div>
                    <div class="text-h5 text-center pa-1">
                      {{ page_worst_monthly_return_value }}
                    </div>
                    <div class="red--text text--darken-1 text-center">
                      {{ page_worst_monthly_return_date }}
                    </div>
                  </v-flex>
                </div>
              </v-card>
            </v-flex>
          </div>
          <!-- -->

          <!-- -->
          <v-card class="mb-2" :ripple="false" v-on:click="openWinVsLossDialog">
            <div class="text-h6 text-center">% Win</div>
            <div class="pa-3">
              <v-progress-linear
                v-model="progress_chart_data_win"
                background-color="red accent-3"
                color="light-green accent-3"
                rounded
                height="20"
              >
              </v-progress-linear>
              <div class="d-flex justify-space-between">
                <!--  grey--text -->
                <div class="subtitle-1 text-center">
                  {{ progress_chart_data_win }}%
                </div>
                <div class="subtitle-1 text-center">
                  {{ progress_chart_data_loss }}%
                </div>
              </div>
            </div>
          </v-card>
          <!-- -->
          <v-card
            class="d-flex flex-column justify-center"
            style="height: 100%"
            v-on:click="openWinVsLossDialog"
          >
            <div class="text-h6 text-center">Day Trade</div>
            <DayTradeChart v-bind:data="data_trade_chart" />
            <!-- <v-chart class="chart" :option="render_dailyReturnChart" autoresize /> -->
          </v-card>
        </div>

        <!-- -->
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="12">
        <v-card>
          <v-card-title class="pb-0">Statistics</v-card-title>
          <!-- <div class="text-h6">Statistics</div> -->
          <v-list v-for="item in numericalList" :key="item.name">
            <v-subheader>{{ item.name }}</v-subheader>
            <v-list-item
              v-for="subitem in item.statistics"
              :key="subitem.name.en"
            >
              <v-list-item-content>
                <v-list-item-title v-text="subitem.name"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <span :class="[subitem.textColor]">{{
                  subitem.value
                }}</span></v-list-item-action
              >
            </v-list-item>
            <!-- <v-divider></v-divider> -->
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense v-show="!allCartLoading">
      <v-col cols="12"> <span class="text-h5"> Setting</span> </v-col>
      <v-col
        cols="12"
        lg="4"
        md="6"
        sm="6"
        v-for="(input, idx) in local_page_data.inputs"
        :key="idx"
      >
        <v-card>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="d-flex justify-space-between">
                <span>{{ input.name.en }}</span>
                <span>{{ input.value }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <!-- -->
    <GrowthDDDialog
      :dialogStatus="dialog_growth"
      :robotId="robot_id"
      @close="dialog_growth = false"
    />
    <HistogramDDDialog
      :dialogStatus="dialog_histogram"
      :robotId="robot_id"
      @close="dialog_histogram = false"
    />
    <MonthlyYearlyReturnDialog
      :dialogStatus="dialog_monthlyYearlyReturn"
      :robotId="robot_id"
      @close="dialog_monthlyYearlyReturn = false"
    />
    <WinVsLossDialog
      :dialogStatus="dialog_winVsLoss"
      :robotId="robot_id"
      @close="dialog_winVsLoss = false"
    />
    <CartDialog
      :dialogStatus.sync="dialog_openCart"
      :dialogData="local_page_data"
      @close="dialog_openCart = false"
    />
    <VerifyDialog
      :dialogStatus.sync="dialog_verify"
      :dialogData="userData"
      @close="dialog_verify = false"
    />
  </v-container>
</template>

<script>
import {
  getRobotDetail,
  getRobotDetailNumerical,
  getRobotDetailAnalytic,
  getInappEntitlement,
} from "@/services/api/robot";
import GrowthChart from "./charts/GrowthChart";
import DailyReturnChart from "./charts/DailyReturnChart";
import DayTradeChart from "./charts/DayTradeChart";
//
import GrowthDDDialog from "./dialog/GrowthDDDialog";
import HistogramDDDialog from "./dialog/HistogramOfReturnDialog";
import MonthlyYearlyReturnDialog from "./dialog/MonthlyYearlyReturnDialog";
import WinVsLossDialog from "./dialog/WinVsLossDialog";
import CartDialog from "./cart-dialog/CartDialog";
import VerifyDialog from "./dialog/VerifyDialog";

// import WinVsLossChart from "./charts/WinVsLossChart";
import { getLocalTimeFromTimestampList } from "@/utils/customDate";
import MainLoading from "../subcomponents/mainLoading.vue";
import _ from "lodash";

export default {
  name: "RobotDetail",
  components: {
    // VChart,
    GrowthChart,
    DailyReturnChart,
    DayTradeChart,
    GrowthDDDialog,
    HistogramDDDialog,
    MonthlyYearlyReturnDialog,
    WinVsLossDialog,
    CartDialog,
    MainLoading,
    VerifyDialog,
    // WinVsLossChart,
  },
  data: () => {
    return {
      robot_id: undefined,
      // page data
      page_data: {
        title: { en: "" },
        subtitle: { en: "" },
      },
      local_page_data: {},
      exceptionNumericalDataKey: ["Avg win (%)", "Avg loss (%)"],
      // dialog
      dialog_growth: false,
      dialog_histogram: false,
      dialog_monthlyYearlyReturn: false,
      dialog_winVsLoss: false,
      dialog_openCart: false,
      dialog_verify: false,
      // chart data
      numericalList: [],
      statisticList: [],
      progress_chart_data_win: 0,
      progress_chart_data_loss: 100,
      page_payoff_ratio_value: 0.0,
      page_best_monthly_return_value: 0.0,
      page_worst_monthly_return_value: 0.0,
      page_best_monthly_return_date: null,
      page_worst_monthly_return_date: null,
      render_growth_option: {},
      render_daily_return_chart_data: null,
      render_daily_return_chart_option: null,
      daily_return_chart_default_chartdata: {
        labels: [],
        datasets: [
          {
            // label: "Group A",
            data: [],
            // backgroundColor: "rgba(255, 99, 132, 1)",
            backgroundColor: "#f1f1f1", //[],
            barPercentage: 1.1,
          },
        ],
      },
      daily_return_chart_default_options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        scales: {
          xAxes: [
            {
              display: true,
              // barPercentage: 1.1,
              ticks: {
                max: 0, // update list
                fontSize: 12,
                callback: function (value) {
                  return `${value} %`;
                },
                fontColor: "#fff",
              },
            },
            // {
            //   display: true,
            //   ticks: {
            //     autoSkip: false,
            //     max: 4,
            //   },
            // },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: "#fff",
              },
              gridLines: {
                color: "#172033",
              },
            },
          ],
        },
      },
      data_trade_chart: null,
      // buy flow
      buyBtnStatus: true,
      buyBtnTextStatus: 0,
      buyStatus: 0,
      buyMethodStatus: false,
      // loading
      allCartLoading: true,
    };
  },
  methods: {
    checkIcon(colorname) {
      if (colorname == "green") {
        return "mdi-arrow-top-right";
      } else if (colorname == "red") {
        return "mdi-arrow-bottom-right";
      } else {
        return "";
      }
    },
    checkIconColor(colorname) {
      if (colorname == "green") {
        return "success";
      } else if (colorname == "red") {
        return "#F44336";
      } else {
        return "";
      }
    },
    // for dialog
    openGrowthDDD() {
      this.dialog_growth = true;
    },
    openHistogramOfReturnDialog() {
      this.dialog_histogram = true;
    },
    openMonthlyYearlyReturnDialog() {
      this.dialog_monthlyYearlyReturn = true;
    },
    openWinVsLossDialog() {
      this.dialog_winVsLoss = true;
    },
    openCartDialog() {
      this.dialog_openCart = true;
    },
    openVerifyDialog() {
      this.dialog_verify = true;
    },
    processStatisticData(data) {
      let _stat = [];
      for (let i = 0; i < data.length; i++) {
        _stat.push({
          name: data[i]["name"]["en"],
          value: data[i]["value"],
          textColor: data[i]["color"] + "--text",
          icon: this.checkIcon(data[i]["color"]),
          iconColor: this.checkIconColor(data[i]["color"]),
        });
      }
      this.statisticList = _.clone(_stat);
    },
    processNumericalData(data) {
      let _all_stat = [];
      for (let i = 0; i < data.length; i++) {
        let _stat = {};
        _stat["name"] = data[i]["group_name"]["en"];
        _stat["statistics"] = [];
        for (let j = 0; j < data[i]["statistics"].length; j++) {
          if (
            !this.exceptionNumericalDataKey.includes(
              data[i]["statistics"][j]["name"]["en"]
            )
          ) {
            _stat["statistics"].push({
              name: data[i]["statistics"][j]["name"]["en"],
              value: data[i]["statistics"][j]["value"],
              textColor: data[i]["statistics"][j]["color"] + "--text",
              icon: this.checkIcon(data[i]["statistics"][j]["color"]),
              iconColor: this.checkIconColor(data[i]["statistics"][j]["color"]),
            });
          }
        }
        _all_stat.push(_stat);
      }
      this.numericalList = _.clone(_all_stat);
    },
    // for daily return chart
    processDailyReturnChart(data) {
      let pre_daily_return_chart_default_chartdata = _.clone(
        this.daily_return_chart_default_chartdata
      );
      let pre_daily_return_chart_default_options = _.clone(
        this.daily_return_chart_default_options
      );

      pre_daily_return_chart_default_chartdata["labels"] = data["x"];
      pre_daily_return_chart_default_chartdata["datasets"][0]["data"] =
        data["y"];
      pre_daily_return_chart_default_options["scales"]["xAxes"][0]["ticks"][
        "max"
      ] = data["x"].length;
      // background process
      let background_color = [];
      for (let i = 0; i < data["x"].length; i++) {
        background_color.push(data["x"][i] < 0 ? "red" : "green");
      }
      pre_daily_return_chart_default_chartdata["datasets"][0][
        "backgroundColor"
      ] = background_color;
      //
      this.render_daily_return_chart_data =
        pre_daily_return_chart_default_chartdata;
      this.render_daily_return_chart_option =
        pre_daily_return_chart_default_options;
    },
    setGrowthData(data) {
      let ts_str = getLocalTimeFromTimestampList(
        data["chart_data"]["backtestWeekChart"]["timeStamp"]
      );

      this.render_growth_option = {
        startCapital: data["chart_data"]["startCapital"],
        data: data["chart_data"]["backtestWeekChart"]["value"],
        xAxisData: ts_str,
        yAxisLabel: "",
      };
    },
    setAnalysisData() {
      this.processDailyReturnChart(
        this.page_analysis_data["histogram_of_return_chart"]["chart_data_s"]
        //
      );
      this.page_payoff_ratio_value =
        this.page_analysis_data["histogram_of_return_chart"]["payoff_ratio"];
      this.page_best_monthly_return_value =
        this.page_analysis_data["best_monthly_return"]["value"];
      this.page_best_monthly_return_date =
        this.page_analysis_data["best_monthly_return"]["yearMonth"];
      this.page_worst_monthly_return_value =
        this.page_analysis_data["worst_monthly_return"]["value"];
      this.page_worst_monthly_return_date =
        this.page_analysis_data["worst_monthly_return"]["yearMonth"];
    },
    setProgressChartData() {
      this.progress_chart_data_win = parseFloat(
        this.page_analysis_data["percent_win"]
      ).toFixed(2);
      this.progress_chart_data_loss = parseFloat(
        100 - this.progress_chart_data_win
      ).toFixed(2);
    },
    setDayTradeData() {
      this.data_trade_chart = this.page_analysis_data["day_trade_chart"];
    },
    // main get data
    async getData() {
      this.page_data = await getRobotDetail(this.robot_id);
      if (this.page_data === undefined) {
        this.redirectToRobotList();
      } else {
        this.local_page_data = _.clone(this.page_data);
      }
      this.page_numerical_data = await getRobotDetailNumerical(this.robot_id);
      this.page_analysis_data = await getRobotDetailAnalytic(this.robot_id);
      // ใช้ histogram_of_return_chart => chart_data_s
      // this.statisticList = res_data["stats"];
      this.processStatisticData(this.page_data["stats"]);
      this.processNumericalData(this.page_numerical_data);
      // let rn = Array.from({length: 40}, () => Math.floor(Math.random() * 40));
      // histogram
      //this.render_dailyReturnChart = this.page_analysis_data['histogram_of_return_chart']['chart_data_s']
      this.setGrowthData(this.page_data);
      this.setAnalysisData();
      this.setProgressChartData();
      this.setDayTradeData();
      this.allCartLoading = false;
    },
    buyAction() {
      //
      if (this.buyStatus == 0) {
        this.$router.push({
          path: "/signin",
        });
      } else if (this.buyStatus == 2) {
        // console.log("go to buy");
        this.openCartDialog();
      } else if (this.buyStatus == 3) {
        // console.log("show verify dialog");
        this.openVerifyDialog();
      } else {
        // already have bot
        this.$router.push({
          path: "/subscriptions",
        });
      }
    },
    async checkCanBuy() {
      if (this.$store.getters.isSessionTokenSet) {
        if (
          this.userData.sign_in_provider == "Email" &&
          this.userData.verified == false
        ) {
          this.buyStatus = 3; // need to verify
          // 1 => BUY, 2 => SETTING, 3 => RENEW
          this.buyBtnTextStatus = 1;
        } else {
          let res_data = await getInappEntitlement(this.robot_id);
          if (res_data.isActive) {
            // check method === 'renew'
            if (this.buyMethodStatus) {
              this.buyStatus = 2; // renew can open cartDialog
              this.buyBtnTextStatus = 3;
              this.openCartDialog();
            } else {
              this.buyStatus = 1; // setting and redict
              this.buyBtnTextStatus = 2;
            }
          } else {
            this.buyStatus = 2; //  buy can open cartDialog
            this.buyBtnTextStatus = 1;
          }
        }
      } else {
        this.buyStatus = 0; // go to sign in
        this.buyBtnTextStatus = 1;
      }
      this.buyBtnStatus = false;
    },
    redirectToRobotList() {
      this.$router.push({
        path: "/tradingbot/",
      });
    },
  },

  computed: {
    userData() {
      let user_data = this.$store.getters.user || {
        email: "",
        name: "",
        verified: false,
        sign_in_provider: null,
      };
      return user_data;
    },
  },
  mounted() {
    this.robot_id = this.$route.query.code;
    this.buyMethodStatus = this.$route.query.method;
    // redirect
    if (this.robot_id === undefined || this.robot_id.length === 0) {
      this.redirectToRobotList();
    }
    if (
      this.buyMethodStatus === undefined ||
      this.buyMethodStatus.length === 0
    ) {
      this.buyMethodStatus = false;
    }

    this.getData();
    this.checkCanBuy();
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
}
.v-subheader {
  height: 24px;
}
.v-card--link:before {
  background: none;
}
.maxHeight {
  height: 100%;
}
</style>
