

<script>
// ref: https://stackoverflow.com/questions/51880101/make-a-histogram-in-chart-js

import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  // props: ["options"],
  props: ["chartData", "options"],
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>

<style scoped>
</style>
