<template>
  <v-dialog
    v-model="show"
    overlay-opacity="0.9"
    :fullscreen="$vuetify.breakpoint.xs"
    max-width="600"
  >
    <v-card dark class="cart-dialog-border">
      <v-toolbar>
        <v-toolbar-title
          >{{ $changeLang(dialogRenderData.title) }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-sheet class="d-flex flex-column" v-if="viewState === 1">
        <v-card outlined class="d-flex flex-column pt-1" light>
          <v-list subheader two-line>
            <v-list-item-group
              v-model="_selected_item_index"
              active-class="border"
              mandatory
              dense
              :ripple="false"
            >
              <v-list-item
                color="white"
                active-class="green"
                v-for="(item, i) in productList"
                :key="i"
                class="selection-bg mb-1"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="title"
                    v-text="item.title"
                  ></v-list-item-title>

                  <v-list-item-subtitle
                    :class="[
                      'subtitle-1 text-wrap',
                      item.id === null ? '' : '',
                    ]"
                    v-text="item.subtitle"
                  ></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <p class="title mb-0" v-if="item.price[currency] > 0">
                    {{ currencySymbols[currency]
                    }}{{ separatorUtilFunc(item.price[currency]) }}
                  </p>
                  <p class="title mb-0" v-if="item.price[currency] == 0">
                    {{ $t("cartDialog.freeText") }}
                  </p>
                  <p
                    class="caption mb-0"
                    v-if="item.discountPercent[currency] > 0"
                  >
                    <span class="red--text">
                      {{ $t("cartDialog.save") }}
                      {{ item.discountPercent[currency] }}%
                    </span>
                  </p>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>

        <v-card outlined class="mt-1" light>
          <div class="d-flex flex-column" style="width: 100%">
            <div class="pa-2">
              <span class="title"> {{ $t("cartDialog.details") }}</span>
            </div>
            <div class="pa-2">
              <p
                class="body-1"
                v-html="
                  $t('cartDialog.detailDesc', { link: tempOfPrivacyLink })
                "
              ></p>
            </div>
          </div>
        </v-card>

        <v-card outlined class="mt-1" light>
          <div class="d-flex flex-column" style="width: 100%">
            <div class="py-1 px-2">
              <span class="title"> {{ $t("cartDialog.referenceCode") }}</span>
            </div>
            <div class="px-2">
              <form>
                <v-text-field
                  color="info"
                  outlined
                  dense
                  v-model="refCode"
                  :label="$t('cartDialog.addReferenceCode')"
                  :error-messages="refCodeErrorMessage"
                  name="refCode"
                ></v-text-field>
              </form>
            </div>
          </div>
        </v-card>

        <v-card outlined class="mt-1" light>
          <div class="d-flex" style="width: 100%">
            <div class="pa-2" style="width: 50%">
              <span class="title">Leverage</span>
            </div>

            <div class="pa-1" style="width: 50%">
              <span class="title">
                <v-select
                  class="ma-0 pa-0"
                  v-model="leverage"
                  :items="leverageList"
                  menu-props="auto"
                  label="Select"
                  item-text="text"
                  item-value="value"
                  hide-details
                  single-line
                  item-color="green"
                  outlined
                  dense
                  color="#000"
                >
                  <template v-slot:item="{ item }">
                    {{ item.text
                    }}<span v-if="item.recommend" class="recommend-text"
                      >({{ $t("cartDialog.recommend") }})</span
                    >
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.text
                    }}<span v-if="item.recommend" class="recommend-text">
                      ({{ $t("cartDialog.recommend") }})</span
                    >
                  </template>
                </v-select>
              </span>
            </div>
          </div>

          <div class="pa-1">
            <p
              class="body-1"
              v-html="
                $t('cartDialog.leverageDesc', { budget: portfolioBalance })
              "
            ></p>
          </div>
        </v-card>

        <v-card outlined light class="mx-auto mt-1">
          <div class="d-flex flex-column" style="width: 100%">
            <div class="pa-2 d-flex">
              <v-avatar class="mr-2" size="26">
                <img :src="require('@/assets/binance_icon.png')" />
              </v-avatar>
              <span class="title">
                {{ $t("cartDialog.connectBinanceTitle") }}
              </span>
            </div>
            <div class="py-1 px-2">
              <p>
                {{ $t("cartDialog.connectBinanceDesc") }}
                <span @click="howToGetKey" class="how_to_get_key">{{
                  $t("cartDialog.connectBinanceHowTo")
                }}</span>
              </p>
            </div>
            <div class="py-1 px-2">
              <v-sheet>
                <v-alert v-if="checkBinanceKeySuccess == 1" type="success">
                  {{ $t("binance.verificationSuccess") }}
                </v-alert>
                <v-alert v-if="checkBinanceKeySuccess == 2" type="error">
                  {{ $t("binance.verificationFailed") }}
                </v-alert>
                <form>
                  <v-text-field
                    color="info"
                    outlined
                    dense
                    v-model="apiKeyField"
                    label="API KEY"
                    required
                    name="apiKey"
                    append-icon="mdi-qrcode-scan"
                    @click:append="uploadQRAction"
                    @input="$v.apiKeyField.$touch()"
                    @blur="$v.apiKeyField.$touch()"
                    :error-messages="apiKeyFieldErrors"
                  ></v-text-field>

                  <v-text-field
                    color="info"
                    outlined
                    dense
                    v-model="apiSecretKeyField"
                    label="Secret Key"
                    required
                    name="apiSecretKey"
                    type="password"
                    @input="$v.apiSecretKeyField.$touch()"
                    @blur="$v.apiSecretKeyField.$touch()"
                    :error-messages="apiSecretKeyFieldErrors"
                  ></v-text-field>
                </form>
                <!-- checkBinanceKeySuccess-->
                <div>
                  <!-- class="text-center"-->
                  <v-btn
                    outlined
                    block
                    @click="checkBinanceKeyAction"
                    :loading="checkbinancekeyloading"
                  >
                    {{ $t("binance.checkBinanceKey") }}
                  </v-btn>
                </div>
              </v-sheet>
            </div>
          </div>
        </v-card>

        <v-btn
          class="mx-auto mt-1"
          tile
          block
          x-large
          color="success"
          v-if="showBuyBtn"
          @click="startBuyAction"
        >
          {{ $t("main.buyNow") }}
        </v-btn>

        <v-btn
          class="mx-auto mt-1"
          tile
          block
          x-large
          color="warning"
          v-if="!showBuyBtn"
          @click="showFacebookContact = true"
        >
          {{ $t("main.contactToFacebook") }}
        </v-btn>
      </v-sheet>

      <!-- loading-->
      <div v-if="viewState === 0" class="text-center">
        <v-progress-circular
          class="pa-5 ma-5"
          indeterminate
          color="green"
        ></v-progress-circular>
      </div>

      <!-- error show connect network -->
      <div v-if="viewState === 2" class="pa-5 text-center">
        <v-icon color="red" size="100"> mdi-close-circle-outline </v-icon>
        <p class="headline text-center" v-html="errorConnectionMessage"></p>
      </div>

      <qrcode-capture ref="file" @detect="onDetect" style="display: none" />
    </v-card>
    <binanceDialog
      :dialogStatus.sync="errorDialog"
      :error_code.sync="error_code"
      @close="errorDialog = false"
    />
    <!-- dialog -->
    <v-dialog v-model="showFacebookContact" width="450">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>Contact us</v-toolbar-title>
        </v-toolbar>
        <br />
        <v-card-text>
          <div class="contactFacebook">
            Payment system on the website is not available at this time. You can
            buy trading bot on our Android Application
            <a href="https://bit.ly/3CGvinB" target="_blank"
              >https://bit.ly/3CGvinB</a
            >
            or message us on Facebook
            <a target="_blank" href="https://www.facebook.com/CryptoMationTH"
              >https://www.facebook.com/CryptoMationTH</a
            >
            If you want to purchase the trading bot. Please contact us on
            Facebook
            <v-divider class="my-1"></v-divider>
            ระบบชำระเงินไม่สามารถให้บริการได้ในขณะนี้
            คุณสามารถซื้อโรบอทเทรดของเราได้ที่บนแอป Android
            <a target="_blank" href="https://bit.ly/3CGvinB"
              >https://bit.ly/3CGvinB</a
            >
            หรือส่งข้อความหาเราที่
            <a target="_blank" href="https://www.facebook.com/CryptoMationTH"
              >https://www.facebook.com/CryptoMationTH</a
            >
          </div>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showFacebookContact = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="refCodeStatus" width="450">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            {{ $t("cartDialog.referenceCodeInVaild") }}
          </v-toolbar-title>
        </v-toolbar>
        <br />
        <v-card-text>
          <div class="contactFacebook">
            {{ $t("cartDialog.referenceCodeInVaildDesc") }}
          </div>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="refCodeStatus = false">{{
            $t("main.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { QrcodeCapture } from "vue-qrcode-reader";
import { separator } from "@/utils/number";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { getLeverageList } from "@/utils/tradingValueSetHelper";
import { checkIPv4 } from "@/utils/customWebBrowser";
import { mapActions } from "vuex";

import {
  checkBinanceKey,
  getProductList,
  getInappTrial,
  getLeverageDetail,
  getClientIp,
  createPrePurchase,
  getInAppFreeTrial,
} from "@/services/api/robot";
import binanceDialog from "@/components/binanace-dialog/errorDialog.vue";
import _ from "lodash";

export default {
  name: "CartDialog",
  mixins: [validationMixin],
  props: ["dialogStatus", "dialogData"],
  components: { QrcodeCapture, binanceDialog },
  validations: {
    apiKeyField: { required },
    apiSecretKeyField: { required },
  },
  data: () => ({
    // dialogRenderData: {
    //   title: { en: "" },
    //   subtitle: { en: "" },
    // },
    viewState: 0, // 0 loading, 1 default, 2 error connection
    errorConnectionMessage: "",
    apiKeyField: "",
    apiSecretKeyField: "",
    selected_item_index: null,
    leverageList: [],
    leverage: 20,
    robot_id: "",
    currency: "thb",
    productList: [],
    refCode: "",
    refCodeErrorMessage: "",
    refCodeStatus: false,
    //
    showBuyBtn: true,
    disbledPaymentProductCanPurchase: false, // true can select payment product
    paymentProductIsAvailable: true,
    showFacebookContact: false, // true
    userCanGetTrial: false,
    //
    getTrialData: {},
    currencySymbols: {
      usd: "$",
      thb: "฿",
    },
    currencyList: [
      { text: "United States Dollar - $", value: "usd" },
      { text: "Thai Baht - ฿", value: "thb" },
    ],
    leverageDetail: {},
    portfolioBalance: 0,
    //
    errorDialog: false,
    error_code: "",
    valid: true,
    checkBinanceKeySuccess: 0,
    checkbinancekeyloading: false,
    //
    tempOfPrivacyLink: "https://crypto-mation.com/privacyandpolicy",
    //
    isSandBox: false,
  }),
  methods: {
    ...mapActions(["getUserInfoOnly"]),
    separatorUtilFunc: separator,
    async getData() {
      // clear variable
      // set default item
      this.apiKeyField = "";
      this.apiSecretKeyField = "";
      this.viewState = 0;
      this.leverageDetail = {};
      this.getTrialData = {};
      this.productList = [];
      this.checkBinanceKeySuccess = 0;
      this.refCodeErrorMessage = "";
      this.refCodeStatus = false;
      //
      this.errorConnectionMessage = this.$t(
        "error.system.errorConnectionMassage"
      );
      //
      this.refCode = this.$store.getters.user.defaultRefCode || "";
      //
      await this.getProduct();
      await this.getLeverage();
      //
      if (this.viewState === 0) {
        this.viewState = 1;
        this._selected_item_index = 0;
        this.selected_item_index = 0;
        this.loadCompleted = true;
      }
    },
    async checkBinanceKeyAction() {
      if (!this.$v.$invalid) {
        this.checkbinancekeyloading = true;
        let validBinance = await checkBinanceKey(
          this.apiKeyField,
          this.apiSecretKeyField
        );
        if (validBinance.verified) {
          this.checkBinanceKeySuccess = 1;
        } else {
          this.error_code = validBinance.description;
          this.errorDialog = true;
          this.checkBinanceKeySuccess = 2;
        }
        this.checkbinancekeyloading = false;
      } else {
        this.$v.$touch();
      }
    },
    async getProduct() {
      let _productItems = [];
      let result = await getProductList(this.robot_id);
      let trialStatusData = await getInappTrial(this.robot_id);
      if (trialStatusData["code"] == 200) {
        this.getTrialData = trialStatusData["data"];
        this.userCanGetTrial = this.getTrialData["trial_offer"];
      } else {
        this.viewState = 2;
        return;
      }

      // for product list
      result.sort(function (a, b) {
        return a.month_period - b.month_period;
      });

      // insert trial data
      if (this.userCanGetTrial === true) {
        _productItems.push({
          id: null,
          period: this.getTrialData["trial_duration"],
          period_unit: "d",
          price: {
            usd: 0,
            thb: 0,
          },
          title: this.$t("cartDialog.freeTrialRange", {
            periods: this.getTrialData["trial_duration"],
          }),
          subtitle: this.$t("cartDialog.freeTrialDesc"),
          discountPercent: {
            thb: 0,
            usd: 0,
          },
        });
      }

      if (this.paymentProductIsAvailable) {
        let lowestPricePerMonth = await this.getPricePerMonth(result);
        for (let product of result) {
          let fullPrice = {
            usd: lowestPricePerMonth.usd * product.month_period,
            thb: lowestPricePerMonth.thb * product.month_period,
          };
          _productItems.push({
            id: product.id,
            period: product.month_period,
            period_unit: "m",
            price: product.price,
            title:
              product.month_period == 12
                ? this.$t("cartDialog.yearRange", { periods: 1 })
                : this.$t("cartDialog.monthRange", {
                    periods: product.month_period,
                  }),
            subtitle: this.$t("cartDialog.subscriptionDesc"),
            // calculate percent
            discountPercent: {
              thb: Math.round(100 - (product.price.thb * 100) / fullPrice.thb),
              usd: Math.round(100 - (product.price.usd * 100) / fullPrice.usd),
            },
          });
        }
      }

      // finally
      this.productList = _.clone(_productItems);
    },
    async getLeverage() {
      this.leverageDetail = await getLeverageDetail(this.robot_id);
      if ("status" in this.leverageDetail) {
        this.viewState = 2;
      } else {
        this.getPortfolioBalance();
      }
    },
    async startBuyAction() {
      await this.checkBinanceKeyAction();
      if (this.checkBinanceKeySuccess != 1) {
        return;
      }

      if (this.selected_item_index !== null) {
        // check currency
        let currencyCode = 840; // usd
        if (this.currency === "thb") {
          currencyCode = 764; // to chillpay code
        }

        let clientIp = await getClientIp();
        if (clientIp === null) {
          //
          // cannot get ip
          //
          this.errorConnectionMessage =
            "Please Check Your Internet Connection. (null ip)";
          this.viewState = 2;
          return;
        } else {
          let _selectedProduct = _.clone(
            this.productList[this.selected_item_index]
          );
          if (_selectedProduct.id === null) {
            // go to free trial
            this.viewState = 0;
            let _res_free_data = await getInAppFreeTrial(
              this.apiKeyField,
              this.apiSecretKeyField,
              this.robot_id,
              this.leverage
            );

            // console.log(_res_free_data);
            if (_res_free_data["code"] != 200) {
              this.errorConnectionMessage =
                "Free Trial Transaction Fail! Press Contact Us.";
              this.viewState = 2;
            } else {
              // go to my subscript
              this.$router.push({
                name: "subscriptions",
              });
            }
          } else {
            // check is ipv4
            let _newIp = "127.0.0.1";
            if (checkIPv4(clientIp) === true) {
              _newIp = clientIp;
            }

            // go to chillpay
            let payload = {
              apiKey: this.apiKeyField,
              secretKey: this.apiSecretKeyField,
              robotId: this.robot_id,
              leverage: this.leverage,
              platform: "web",
              refCode: this.refCode,
              chillPay: {
                amount: _selectedProduct.price[this.currency],
                orderno: null,
                customerid: null,
                mobileno: null,
                clientip: _newIp,
                currency: currencyCode,
                description: _selectedProduct.id,
              },
            };
            //
            if (this.isSandBox === true) {
              payload["sandbox"] = this.isSandBox;
            }
            //
            let resp_data = await createPrePurchase(payload);
            if (resp_data["code"] === 200) {
              // update ref code.
              await this.getUserInfoOnly();
              //
              let redirectHref = this.$router.resolve({
                name: "ChillPayMerchantPage",
                query: { order: resp_data["data"]["orderno"] },
              });
              window.location.href = redirectHref.href;
            } else {
              // 400 - ERROR_REF_CODE
              if (resp_data["message"] === "ERROR_REF_CODE") {
                this.refCodeStatus = true;
                this.refCodeErrorMessage = this.$t(
                  "cartDialog.referenceCodeInVaild"
                );
              } else {
                this.errorConnectionMessage =
                  "Purchase Failed Cannot Connect to Internet.";
                this.viewState = 2;
              }
            }
          }
        }
      }
    },
    getPricePerMonth(packages) {
      // process price permount
      let pricePerMonth = {
        usd: 0,
        thb: 0,
      };
      if (packages.length > 0) {
        (pricePerMonth.usd =
          packages[0].price["usd"] / packages[0].month_period),
          (pricePerMonth.thb =
            packages[0].price["thb"] / packages[0].month_period);
      }
      return pricePerMonth;
    },
    getPortfolioBalance() {
      // console.log(this.leverageDetail);
      let _balance = 0;
      if (this.leverageDetail) {
        _balance =
          (this.leverageDetail.entryQuantity /
            ((this.leverageDetail.lotPercent /
              100 /
              this.leverageDetail.currentPrice) *
              this.leverage)) *
          2;
      }
      this.portfolioBalance = _.clone(_balance.toFixed(2));
    },
    howToGetKey() {
      window.open("https://crypto-mation.com/docs/apikey", "_blank");
    },
    //
    uploadQRAction() {
      this.$refs.file.$el.click();
    },
    async onDetect(promise) {
      try {
        const {
          //   imageData, // raw image data of image/frame
          content, // decoded String or null
          //   location, // QR code coordinates or null
        } = await promise;

        if (content === null) {
          // decoded nothing
          this.apiKeyField = "";
          this.apiSecretKeyField = "";
        } else {
          let decodeResult = JSON.parse(content);
          this.apiKeyField = decodeResult.apiKey;
          this.apiSecretKeyField = decodeResult.secretKey;
          this.$refs.file.$el.value = "";
          this.checkBinanceKeyAction();
        }
      } catch (error) {
        // console.log(error);
        this.$refs.file.$el.value = "";
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.dialogStatus;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    dialogRenderData: {
      get() {
        if (Object.keys(this.dialogData).length > 0) {
          return this.dialogData;
        } else {
          return { title: { en: "" }, subtitle: { en: "" } };
        }
      },
      set(value) {
        return value;
      },
    },
    apiKeyFieldErrors() {
      const errors = [];
      if (!this.$v.apiKeyField.$dirty) return errors;
      !this.$v.apiKeyField.required &&
        errors.push(this.$t("binance.apiKeyRequired"));
      return errors;
    },
    apiSecretKeyFieldErrors() {
      const errors = [];
      if (!this.$v.apiSecretKeyField.$dirty) return errors;
      !this.$v.apiSecretKeyField.required &&
        errors.push(this.$t("binance.apiSecretRequired"));
      return errors;
    },
    //
    _selected_item_index: {
      get() {
        return 0;
      },
      set(value) {
        if (value !== 0) {
          if (this.disbledPaymentProductCanPurchase) {
            // true
            this.showBuyBtn = false;
            this.showFacebookContact = true;
          }
        } else {
          this.showBuyBtn = true;
        }
        this.selected_item_index = value;
        return value;
      },
    },
  },
  mounted() {
    this.robot_id = this.$route.query.code;
    // setting
    this.leverageList = getLeverageList();
  },
  watch: {
    dialogStatus(visible) {
      if (visible) {
        this.getData();
      }
    },
    leverage(value) {
      if (value) {
        this.getPortfolioBalance();
      }
    },
  },
};
</script>

<style lang="scss">
.recommend-text {
  margin-left: 10px;
  color: grey;
}
.how_to_get_key {
  color: deepskyblue;
  text-decoration-line: underline;
  cursor: pointer;
}

.selection-bg {
  margin-left: 0.2em;
  margin-right: 0.2em;
  border: 2px solid rgb(216, 216, 216);
  background-color: #f6f5f5;
  border-radius: 0.5em;
}

.cart-dialog-border {
  // border: 0.2em solid #e9e9e9 !important;
  border: 0.2em solid #172033 !important;
  border-radius: 0.05em;
  // padding: 0.5em;
}

.contactFacebook {
  /* unvisited link */
  a:link {
    color: white;
  }

  /* visited link */
  a:visited {
    color: white;
  }

  /* mouse over link */
  a:hover {
    color: white;
  }

  /* selected link */
  a:active {
    color: white;
  }
}
</style>
