<template>
  <v-chart class="chart" :option="option" autoresize />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import _ from "lodash";

use([
  GridComponent,
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: "DayTrade",
  props: ["data"],
  components: {
    VChart,
  },
  data: () => {
    return {
      option: null,
      default_option: {
        grid: {
          top: 8,
          right: "5%",
          left: "5%",
        },
        tooltip: {
          trigger: "item",
          // formatter: "{a} <br/>{b}: {c} ({d}%)",
          formatter: "{d}%",
        },
        series: [
          {
            name: "Day Trade chart",
            type: "pie",
            selectedMode: "single",
            // radius: [0, "30%"],
            label: {
              position: "inner",
              fontSize: 16,
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      },
      orderDays: ["mon", "thu", "wed", "tue", "fri", "sat", "sun"],
    };
  },
  methods: {
    dayOfWeekColor(day_name) {
      let colors = {
        // 'mon':'#FFFAF0',
        // 'thu':'#CD5C5C',
        // 'wed':'#32CD32',
        // 'tue':'#FFFFE0',
        // 'fri':'#F0F8FF',
        // 'sat':'#663399',
        // 'sun':'#800000',
        // mon: "yellow",
        // thu: "pink",
        // wed: "green",
        // tue: "orange",
        // fri: "blue",
        // sat: "purple",
        // sun: "red",
        mon: "#003f5c",
        thu: "#374c80",
        wed: "#7a5195",
        tue: "#bc5090",
        fri: "#ef5675",
        sat: "#ff764a",
        sun: "#ffa600",
      };
      return colors[day_name];
    },
    fullNameDay(day_name) {
      let names = {
        mon: "Mon",
        thu: "Thu",
        wed: "Wed",
        tue: "Tue",
        fri: "Fri",
        sat: "Sat",
        sun: "Sun",
      };

      return names[day_name];
    },
  },
  watch: {
    data: function (newVal) {
      
      let _new_options = _.clone(this.default_option);
      //   console.log("Prop changed: ", newVal, " | was: ", oldVal);
      for (let i = 0; i < this.orderDays.length; i++) {
        // _orderedDay.push(newVal[this.orderDays[i]]);
        _new_options["series"][0]["data"].push({
          value: newVal[this.orderDays[i]],
          name: this.fullNameDay(this.orderDays[i]),
          itemStyle: { color: this.dayOfWeekColor(this.orderDays[i]) },
        });
      }
      // process
      // {
      //   value: 90,
      //   name: "Win rate",
      //   selected: true,
      //   itemStyle: { color: "#76FF03" },
      // },
      // {
      //   value: 10,
      //   name: "Loss rate",
      //   itemStyle: { color: "#FF1744" },
      // },
      // _new_options["series"][0]["data"] = newVal;
      this.option = _new_options;
    },
  },
  mounted() {
    // console.log(this.data);
  },
};
</script>

<style scoped>
</style>
